import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Keep this input order to make your custom styles replace bootstrap css.
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import 'flickity/dist/flickity.min.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.unregister();

reportWebVitals();
