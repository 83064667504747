import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCommentDots, faUser, faBell } from '@fortawesome/free-solid-svg-icons';

import Notification from '../notification';

import { isAuthenticated as isLoggedIn } from '../../utils/auth';

import { setLayout } from '../../actions/layout';

import crBox from '../../assets/images/crbox.svg';
import colorBox from '../../assets/images/colorbox.svg';

import './nav-footer.scss';

const navPaths = [
  '/',
  '/notifications',
  '/rooms',
  '/messaging',
  '/dashboard'
]

const NavFooter = ({
  auth: {
    isAuthenticated,
    loading,
    user
  },
  message: {
    totalUnreadCount,
    unreadChannels
  },
  setLayout,
  layout
}) => {
  const location = useLocation();

  const [isDarkMode, setDarkMode] = useState(false);
  const [isStandAlone, setIsStandAlone] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false);
  const [showMessageNotification, setMessageNotification] = React.useState(false);
  const [activePage, setActivePage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showAuthLinks, setShowAuthLinks] = useState(false);

  useEffect(() => {
    if (navPaths.includes(location.pathname)) {
      setActivePage(location.pathname.split('/')[1] || null);
    }
    const slugs = location.pathname.split('/').filter(x => x);

    if (slugs.length >= 2 && slugs[1] === 'venue') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

  }, [location]);

  useEffect(() => {
    const resizeListener = () => {
      const currentInnerHeight = window.innerHeight;
      if (currentInnerHeight > innerHeight) {
        setIsBottomBarHidden(true);
      } else {
        setIsBottomBarHidden(false);
      }

      setInnerHeight(currentInnerHeight);
    }

    if (!isStandAlone) {
      window.addEventListener('resize', resizeListener);
    } else {
      setIsBottomBarHidden(true);
    }

    return () => {
      window.removeEventListener('resize', resizeListener);
    }

  }, [isStandAlone]);

  useEffect(() => {
    setIsStandAlone(navigator.standalone);
    setShowAuthLinks(isLoggedIn());
  }, []);

  useEffect(() => {
    setShowAuthLinks(isLoggedIn());
  }, [isAuthenticated])

  useEffect(() => {
    if (totalUnreadCount) {
      setMessageNotification(true);
    } else {
      setMessageNotification(false);
    }
  }, [totalUnreadCount]);

  useEffect(() => {
    setLayout({ isBottomBarHidden });
  }, [isBottomBarHidden])

  useEffect(() => {
    setLayout({ showNotification });
  }, [showNotification]);

  useEffect(() => {
    if (!layout?.showNotification) {
      setShowNotification(false);
    }
  }, [layout, layout?.showNotification]);

  const toggleNotification = () => {
    const bool = !showNotification;

    setShowNotification(bool);
  }


  const authLinks = useMemo(() => (
    <Fragment>
      <ul className="nav">
        <li className="nav-item">
          <Link to="/" className={!activePage && 'active'}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </li>
        <li className="nav-item">
          <Link onClick={()=>toggleNotification()} className={showNotification && 'active'}>
            <FontAwesomeIcon icon={faBell} />
          </Link>
        </li>
        <li className="nav-item">
          <Link to='/rooms'>
            <img className="cr-box" src={activePage==='rooms' ? colorBox : crBox}></img>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/messaging" style={{"position": "relative"}} className={activePage==='messaging' && 'active'}>
            <FontAwesomeIcon icon={faCommentDots} />
            {showMessageNotification ? <span className="notification-circle">
            {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
            </span> : ''}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard" className={activePage==='dashboard' && 'active'}>
            <FontAwesomeIcon icon={faUser} />
          </Link>
        </li>
      </ul>
    </Fragment>
  ), [showAuthLinks, showMessageNotification, activePage, totalUnreadCount, showNotification]);

  const guestLinks = useMemo(() => (
    <ul className='nav grid-col-2'>
      <li className="nav-item">
        <Link to='/login'>Log In</Link>
      </li>
      {/* <li className="nav-item">
        <Link to='/register'>
          <button className="btn btn-outline-danger rounded-pill font-weight-bold px-4">Sign Up</button>
        </Link>
      </li> */}
    </ul>
  ), [showAuthLinks])

  return (
    <Fragment>
      {showAuthLinks ? <div className={`footer-wrapper ${isDarkMode && 'hide'} ${isBottomBarHidden && 'bottom-bar-hidden'}`}>
        <nav className="navbar container">
          {authLinks}
        </nav>
        {showNotification && <Notification toggleNotification={toggleNotification}/>}
      </div> : ''}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  layout: state.layout
});

export default connect(mapStateToProps, {setLayout})(NavFooter);
