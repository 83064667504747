import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Get storage.
 *
 * @param {String} key : whose value to get.
 */
 export function get(key) {
  return cookies.get(key);
}

/**
 * Set storage.
 *
 * @param {String} key : whose Value to set.
 * @param {String} value : Value to set.
 */
export function set(key, value) {
  const expDays = 10;
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));

  cookies.set(key, value, {
    path: '/',
    expires: date,
    // domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`
  });
}

/**
 * Remove key value pair in storage.
 *
 */
export function remove() {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}
