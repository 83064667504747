import {
	GET_PROFILE,
	PROFILE_ERROR,
	CLEAR_PROFILE,
	UPDATE_PROFILE,
	UPDATE_SOCIAL_MEDIA,
	GET_PROFILES,
	GET_USER_PROFILE,
	IMAGE_UPLOAD,
	IMAGE_UPLOAD_SUCCESS,
	IMAGE_UPLOAD_FAIL,
	GET_PROFILE_PENDING
} from '../actions/types';

const initialState = {
	profile: null,
	profiles: [],
	friendRequests: [],
	loading: true,
	error: {},
	events: [],
	stripeInfo: {},
	loadingBalance: false,
	loadingEventPayments: false,
	loadingPayout: false
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_PROFILE_PENDING:
			return {
				...state,
				loading: true
			}
		case GET_PROFILE:
		case UPDATE_PROFILE:
			return {
				...state,
				profile: { ...state.profile, ...payload },
				loading: false,
			};
		case UPDATE_SOCIAL_MEDIA:
			return {
				...state,
				loading: true
			};
		case GET_PROFILES:
			return {
				...state,
				profiles: payload,
				loading: false,
			};
		case PROFILE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
				profile: null,
			};
		case CLEAR_PROFILE:
			return {
				...state,
				profile: null,
			};

			return {
				...state,
				respondRequestLoading: false,
				error: payload,
			};

			return {
				...state,
				respondRequestLoading: false,
				error: payload,
			};
		case GET_USER_PROFILE:
			return {
				...state,
				profile: { ...state.profile, otherUser: payload },
				loading: false,
			};
		case IMAGE_UPLOAD:
			return {
				...state,
				loading: true,
			};
		case IMAGE_UPLOAD_SUCCESS:
			return {
				...state,
				profile: {
					...state.profile,
					otherUser: {
						...state.profile.otherUser,
						user: { ...state.profile.otherUser.user, avatar: payload },
					},
					user: { ...state.profile.user, avatar: payload },
				},
				loading: false,
			};
		case IMAGE_UPLOAD_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}
