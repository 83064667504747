import { StreamChat } from 'stream-chat';

let instance = null;

class GetStreamUtil {
	constructor() {
		if (instance) {
			return instance;
		}

		this.initializing = false;
		this.chatClient = new StreamChat(process.env.REACT_APP_GETSTREAM_APP_KEY, { timeout: 6000 });
		this.isUserSet = false;
		instance = this;
	}

	setUser(user) {
		return new Promise(async (resolve, reject) => {
			try {
				this.initializing = true;
				const response = await this.chatClient.setUser({
					id: user.id,
					name: user.name,
					image: user.avatar || user.image,
				},
					user.gs_token
				);
				this.initializing = false;
				this.isUserSet = true;
				resolve(response);
			} catch (error) {
				reject(error);
			}
		})
	}

	async disconnect() {
		return new Promise(async (resolve, reject) => {
			try {
				await this.chatClient.disconnect();
				this.isUserSet = false;

				resolve();
			} catch(error) {
				reject(error);
			}
		})
	}

	async conversation(users) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await this.chatClient.channel(
					'messaging',
					{
						members: [...users],
						chatType: 'one-one'
					}
				)

				resolve(response);
			} catch (error) {
				reject(error);
			}
		})
	}

	async liveConversation(channel) {
		return new Promise(async(resolve, reject)=>{
			try {
				const response = await this.chatClient.channel(
					'livestream',
					channel.id,
					{
						image: channel.image,
						name: channel.name,
					}
				);

				resolve(response);
			} catch(error) {
				reject(error);
			}
		})
	}

	static getInstance() {
		return new GetStreamUtil();
	}
}

export { GetStreamUtil };
