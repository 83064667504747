import {
  SET_MESSAGE_COUNT
} from './types';

export const setMessageCount = (message_count) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE_COUNT,
    payload: message_count,
  })
};
