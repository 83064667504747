import {
  GET_EVENT_PAYMENTS_PENDING,
  GET_EVENT_PAYMENTS_FULFILLED,
  GET_EVENT_PAYMENTS_REJECTED,
  GET_BALANCE_PENDING,
  GET_BALANCE_FULFILLED,
  GET_BALANCE_REJECTED,
  GOTO_STRIPE_DASHBOARD_PENDING,
  GOTO_STRIPE_DASHBOARD_FULFILLED,
  GOTO_STRIPE_DASHBOARD_REJECTED,
  PAYOUT_FULFILLED,
  PAYOUT_PENDING,
  PAYOUT_REJECTED
} from '../actions/types';

const initialState = {
  events: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENT_PAYMENTS_FULFILLED:
      return {
        ...state,
        events: payload?.data || []
      };
    case GET_EVENT_PAYMENTS_PENDING:
      return state;
    case GET_EVENT_PAYMENTS_REJECTED:
      return state;
    case GET_BALANCE_FULFILLED:
      return {
        ...state,
        stripeInfo: payload
      }
    case GET_BALANCE_PENDING:
    case GET_BALANCE_REJECTED:
      return state;
    case PAYOUT_PENDING:
      return {
        ...state,
        loadingPayout: true
      }
    case PAYOUT_FULFILLED:
    case PAYOUT_REJECTED:
      return {
        ...state,
        loadingPayout: false
      }
    case GOTO_STRIPE_DASHBOARD_PENDING:
      return {
        ...state,
        loading: true
      }
    case GOTO_STRIPE_DASHBOARD_REJECTED:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}
