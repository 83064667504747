import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import room from './room';
import event from './event';
import notification from './notification';
import message from './message';
import layout from './layout';
import payment from './payment';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  room,
  event,
  notification,
  message,
  layout,
  payment
});
