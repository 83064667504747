import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMoralis } from 'react-moralis';

import Spinner from '../layout/Spinner';

import { getConnectorId } from '../../utils/token';

const Alert = React.lazy(() => import('../layout/Alert'));
const ToastComponent = React.lazy(() => import('../layout/ToastComponent'));
// const Dashboard = React.lazy(() => import('../dashboard/Dashboard'));
const ProfileForm = React.lazy(() => import('../profile-forms/ProfileForm'));
const Profiles = React.lazy(() => import('../profiles/Profiles'));
const Profile = React.lazy(() => import('../profile/Profile'));

const LandingPage = React.lazy(() => import('../landing'));
const Venue = React.lazy(() => import("../venue/Venue"));

const NotFound = React.lazy(() => import('../layout/NotFound'));
const PrivateRoute = React.lazy(() => import('../routing/PrivateRoute'));
const PostForm = React.lazy(() => import('../posts/PostForm'));
const Messaging = React.lazy(() => import('../messaging/Messaging'));
const Privacy = React.lazy(() => import('../privacy-terms-conditions/privacy'));
const TermConditions = React.lazy(() => import('../privacy-terms-conditions/terms-conditions'));
const Faq = React.lazy(() => import('../faq'));
const StripeCallBackPage = React.lazy(() => import('../stripe'));
const CreatorDashboard = React.lazy(() => import('../creator-tools'));
const PageUnderConstruction = React.lazy(() => import('../page-under-construction'));
const Room = React.lazy(() => import('../../pages/Room'));
const Rooms = React.lazy(() => import('../../pages/Rooms'));
const Dashboard = React.lazy(() => import('../../pages/Dashboard'));

const modalPaths = [
	"/login",
	"/register",
	"/forgot-password",
	"/reset-password",
	"/code-input",
	"/dashboard/creator-tools"
];

const Routes = (props) => {
	const location = useLocation();
	const layout = useSelector(state => state.layout);
	const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } = useMoralis();

	useEffect(() => {
		if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
			enableWeb3({ provider: getConnectorId() });
	}, [isAuthenticated, isWeb3Enabled]);
	return (
		<Suspense fallback={<Spinner/>}>
			<main className={`main-container ${layout?.isBottomBarHidden ? 'bottom-bar-hidden' : 'bottom-bar-shown'}`}>
				{!modalPaths.includes(location.pathname) && <Alert />}
				<ToastComponent />
				<Switch>
					<Route exact path='/faq' component={Faq} />
					<Route exact path='/privacy-policy' component={Privacy} />
					<Route exact path='/terms-of-use' component={TermConditions} />
					<Route exact path='/profiles' component={Profiles} />

					<Route exact path={['/', '/login', '/username-input', '/contact-us']} component={LandingPage} />

					<PrivateRoute exact path='/dashboard' component={Dashboard} />
					<PrivateRoute exact path='/dashboard/creator-tools' component={CreatorDashboard} checkStripeConnection={true} />
					<PrivateRoute exact path='/dashboard/creator-tools/payments' component={CreatorDashboard}></PrivateRoute>
					<PrivateRoute exact path='/create-profile' component={ProfileForm} />
					<PrivateRoute exact path='/createPost' component={PostForm} />
					<PrivateRoute exact path='/messaging' component={Messaging} />
					<PrivateRoute exact path='/profile/:username' component={Profile} />
					<PrivateRoute exact path='/stripe/success' component={StripeCallBackPage} />
					<PrivateRoute exact path='/stripe/refresh' component={StripeCallBackPage} />
					<PrivateRoute exact path='/notifications' component={PageUnderConstruction} />
				/* Room */
					<PrivateRoute exact path='/rooms' component={Rooms} />
					<PrivateRoute exact path='/:routeKey/venue' component={Venue} />
					<PrivateRoute
						exact
						path='/:routeKey/venue/:stageName'
						component={Venue}
					/>
					<Route path='/:routeKey' component={Room} />
					<Route component={NotFound} />
				</Switch>
			</main>
		</Suspense>
	);
};

export default Routes;
