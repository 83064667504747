import HttpStatus from 'http-status-codes';

import { creatorApi, authApi } from '../utils/api';
import { logout } from '../actions/auth';
import * as TokenUtil from '../utils/token';

let heldRequests = [];
let isTokenBeingRefreshed = false;

/**
 * Build authorization header.
 *
 * @param {string} accessToken
 *
 * @returns {string}
 */
function getAuthorizationHeader(accessToken) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {object} request
 *
 * @returns {object}
 */
export async function authorizationInterceptor(request) {
  const accessToken = TokenUtil.getAccessToken();

  if (!accessToken) redirectToLogin();
  if (accessToken && !request.headers['Authorization']) {
    request.headers['Authorization'] = getAuthorizationHeader(accessToken);
  }
  
  return request;
}

/**
 * Interceptor to refresh Authorization header.
 *
 * @param {object} error
 *
 * @returns {object}
 */
export async function unauthorizedResponseHandlerInterceptor(error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  const path = originalRequest.url;

  const isUnAuthorized =
    error.response.status === HttpStatus.UNAUTHORIZED;
  
  if (isUnAuthorized && path === `/auth/refresh-token`) return redirectToLogin();

  if (isUnAuthorized) {
    const refreshToken = TokenUtil.getRefreshToken();

    if (!refreshToken) return redirectToLogin();

    if (isTokenBeingRefreshed) {
      try {
        const newAccessToken = await holdRequest();

        originalRequest.headers['Authorization'] = getAuthorizationHeader(
          newAccessToken
        );

        return creatorApi.request(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    isTokenBeingRefreshed = true;

    try {
      await refreshTokens();

      isTokenBeingRefreshed = false;
      const accessToken = TokenUtil.getAccessToken();

      originalRequest.headers['Authorization'] = getAuthorizationHeader(
        accessToken
      );

      releaseHeldRequests(null, accessToken);

      return creatorApi.request(originalRequest);
    } catch (err) {
      releaseHeldRequests(err, null);
      if (err && err.status === HttpStatus.UNAUTHORIZED) {
        redirectToLogin();
      }
    }
  }

  return Promise.reject(error);
}

/**
 * Release held requests.
 *
 * @param {object} err
 * @param {string} refreshedAccessToken
 */
function releaseHeldRequests(err, refreshedAccessToken = null) {
  heldRequests.forEach((elementPromise) => {
    if (err) {
      elementPromise.reject(err);
    } else {
      elementPromise.resolve(refreshedAccessToken);
    }
  });
  heldRequests = [];
}

/**
 * Hold request in array.
 *
 * @returns {Promise<Array>}
 */
function holdRequest() {
  return new Promise((resolve, reject) => {
    heldRequests.push({ resolve, reject });
  });
}

/**
 * Redirects to the login page.
 */
export function redirectToLogin() {
  logout();
}

const refreshTokens = async () => {
  try {
    const { data } = await authApi.post('/refresh-token', {
      refreshToken: TokenUtil.getRefreshToken()
    });
    const { refreshToken, accessToken } = data;
    TokenUtil.setAccessToken(accessToken);
    TokenUtil.setRefreshToken(refreshToken);
  } catch (err) {
    TokenUtil.setAccessToken('');
    TokenUtil.setRefreshToken('');
    throw err;
  }
}

