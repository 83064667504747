import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import './index.scss';

const Notification = ({
  toggleNotification
}) => {
  return <Fragment>
    <Card className="notification-container">
      <Card.Title>
        <span>
          Notifications
        </span>
        <span className="notification-close">
          <FontAwesomeIcon icon={faTimes} onClick={toggleNotification}/>
        </span>
      </Card.Title>
      <Card.Text>
        Nothing yet.
      </Card.Text>
    </Card>
  </Fragment>
}

export default Notification;