import {
	REGISTER,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOAD_USER_PENDING,
	LOAD_USER_SUCCESS,
	LOAD_USER_FAILED,
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	ACCOUNT_DELETED,
	UPDATE_PAYMENT_METHOD,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_FAILED,
	RESEND_CONFIRMATION_EMAIL,
	RESEND_CONFIRMATION_EMAIL_SUCCESS,
	VERIFY_CODE,
	VERIFY_CODE_SUCCESS,
	VERIFY_CODE_FAILED,
	CLEAR_DEVICES,
	CLEAR_DEVICES_SUCCESS,
	CLEAR_DEVICES_FAILED,
	CLEAR_ERROR,
	LOGOUT_PENDING,
	LOGOUT_SUCCESS,
	LOGOUT_FAILED,
	SIGNUP_WITH_MM,
	CHECK_USER_EXISTS_FAILED
} from '../actions/types';

const initialState = {
	isAuthenticated: null,
	isVerified: null,
	loading: false,
	user: null
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAD_USER_PENDING: 
			return {
				...state,
				loading: true
			}
		case LOAD_USER_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		case LOAD_USER_FAILED:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				user: null
			}
		case REGISTER:
			return  {
				...state, 
				loading: true
			}
		case REGISTER_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case REGISTER_FAIL:
			return {
				...state,
				loading: false,
			};
		case LOGIN: {
			return {
				...state, 
				loading: true
			}
		}
		case LOGIN_SUCCESS:
			return {
				...state,
				...payload,
				isAuthenticated: true,
				isConfirming: false,
				loading: false,
			};
		case LOGIN_FAIL:
			return {
				...state,
				loading: false,
				error: payload
			};
		case CLEAR_DEVICES:
			return {
				...state,
				loading: true,
				error: null
			}
		case CLEAR_DEVICES_SUCCESS:
			return {
				...state,
				error: null
			}
		case CLEAR_DEVICES_FAILED:
			return {
				...state,
				loading: false,
				error: payload
			}
		case ACCOUNT_DELETED:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
			};
		case LOGOUT_PENDING:
			return { ...state, loading: true };
		case LOGOUT_SUCCESS:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
			};
		case LOGOUT_FAILED:
			return { ...state, laoding: false };
		case UPDATE_PAYMENT_METHOD:
			return {
				...state,
				updatingPaymentMethod: true,
			};
		case UPDATE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					paymentMethod: payload,
					payment_method_id: payload.id,
				},
				updatingPaymentMethod: false,
			};
		case UPDATE_PAYMENT_METHOD_FAILED:
			return {
				...state,
				error: payload,
				updatingPaymentMethod: false,
			};
		case 	RESEND_CONFIRMATION_EMAIL_SUCCESS:
			return {
				...state, 
				error: null
			}
		case VERIFY_CODE:
			return {
				...state,
				loading: true
			}
		case VERIFY_CODE_SUCCESS:
			return {
				...state,
				loading: false,
				isVerified: true
			}
		case VERIFY_CODE_FAILED:
			return {
				...state,
				loading: false
			}
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			}
		case SIGNUP_WITH_MM:
			return {
				...state,
				loading: true
			}
		case CHECK_USER_EXISTS_FAILED:
			return {
				...state,
				loading: false
			}
		default:
			return state;
	}
}
