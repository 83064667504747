import axios from 'axios';
import api, { creatorApi } from '../utils/api';
import { isAuthenticated } from '../utils/auth';
import { setAlert } from './alert';
import {
  GET_POSTS,
  GET_USER_POSTS,
  GET_ROOM_POSTS,
  GET_EVENT_POSTS,
  GET_UPDATED_POST,
  POST_ERROR,
  UPDATE_LIKES,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_DRAFTS,
  GET_POST_DRAFTS_SUCCESS,
  GET_POST_DRAFTS_FAILED,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED,
  GET_COMMENTS,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  CLEAR_POSTS
} from './types';

const SERVER_URL = process.env.REACT_APP_CREATOR_SERVER_URL;

// Get posts
export const getPosts = () => async dispatch => {
  try {
    const res = await api.get('/posts');

    dispatch({
      type: GET_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add like
export const upvote = (postId, commentId, subCommentId, userId) => async dispatch => {
  try {
    let url = '';

    if (postId && commentId && subCommentId) {
      url = `/posts/${postId}/comment/${commentId}/sub-comment/${subCommentId}/upvotes`;
    } else if (postId && commentId) {
      url = `/posts/${postId}/comment/${commentId}/upvotes`;
    } else if (postId) {
      url = `/posts/upvotes/${postId}`;
    } else {
      return;
    }
    const res = api.put(url);

    dispatch({
      type: UPDATE_LIKES,
      payload: { postId, commentId, subCommentId, userId, type: 'upvote' }
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Remove like
export const downvote = (postId, commentId, subCommentId, userId) => async dispatch => {
  try {
    let url = '';

    if (postId && commentId && subCommentId) {
      url = `/posts/${postId}/comment/${commentId}/sub-comment/${subCommentId}/downvotes`;
    } else if (postId && commentId) {
      url = `/posts/${postId}/comment/${commentId}/downvotes`;
    } else if (postId) {
      url = `/posts/downvotes/${postId}`;
    } else {
      return;
    }
    const res = api.put(url);

    dispatch({
      type: UPDATE_LIKES,
      payload: { postId, commentId, subCommentId, userId, type: 'downvote' }
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add post
export const addPost = formData => async dispatch => {
  try {
    const res = await api.post('/posts', formData);

    dispatch({
      type: ADD_POST,
      payload: res.data
    });

    dispatch(setAlert('Post Created', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get post
export const getPost = id => async dispatch => {

  try {
    const res = await api.get(`/posts/${id}`);

    const { data } = res;

    dispatch({
      type: GET_POST,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get User's posts
export const getUserPosts = id => async dispatch => {
  try {

    const res = await api.get(`/posts/user/${id}`);

    dispatch({
      type: GET_USER_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Room's posts
export const getRoomPosts = roomName => async dispatch => {
  try {

    const res = await api.get(`/posts/room/${roomName}`);

    dispatch({
      type: GET_ROOM_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Event's posts
export const getEventPosts = (eventName, page) => async dispatch => {
  if (!eventName) {
    return;
  }
  let url = `/posts/event/${eventName}?page=${page || 1}`;
  const auth = isAuthenticated();

  if (!auth) {
    url += `?no_auth=${true}`;
  }
  

  try {
    const res = await api.get(url);

    dispatch({
      type: GET_EVENT_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err?.response?.statusText || 'Something went wrong', status: err?.response?.status }
    });
  }
};

// Get post
export const getUpdatedPost = id => async dispatch => {
  if (!id) return;
  try {
    const res = await api.get(`/posts/${id}`);

    dispatch({
      type: GET_UPDATED_POST,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add comment
export const addComment = (postId, formData, commentId) => async dispatch => {
  try {
    let res = {};

    if (postId && commentId) {
      res = await api.post(`posts/${postId}/comment/${commentId}/sub-comment`, formData);
    } else if (postId) {
      res = await api.post(`/posts/${postId}/comment`, formData);
    } else {
      return;
    }

    dispatch({
      type: ADD_COMMENT,
      payload: { comments: res.data, postId }
    });

    dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    const error = err?.response?.data?.error || 'Something went wrong';
    dispatch({
      type: POST_ERROR,
      payload: { msg: error, status: err?.response?.status }
    });
  }
};

// Delete comment
export const deleteComment = (postId, commentId, parentCommentId) => async dispatch => {
  try {
    if (postId && commentId && parentCommentId) {
      await api.delete(`/posts/${postId}/comment/${parentCommentId}/sub-comment/${commentId}`);
    } else if (postId && commentId) {
      await api.delete(`/posts/${postId}/comment/${commentId}`);
    }

    dispatch({
      type: REMOVE_COMMENT,
      payload: { postId, commentId, parentCommentId }
    });

    dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// ----------------------- New Post Features --------------------
export const getPostDrafts = (eventId) => async dispatch => {
  try {
    dispatch({
      type: GET_POST_DRAFTS
    })

    const res = await api.get(`/posts/event/${eventId}/draft`);

    dispatch({
      type: GET_POST_DRAFTS_SUCCESS,
      payload: { drafts: res.data }
    })
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: GET_POST_DRAFTS_FAILED
    });
  }
}

export const createPost = (payload) => async dispatch => {
  try {
    const { data } = await creatorApi.post(`/posts`, payload);

    return data?.response;
  } catch (err) {
  }
}

export const saveDraft = (postId, payload) => async dispatch => {
  try {
    const res = await creatorApi.put(`/posts/${postId}`, payload);

    return res.data;
  } catch (err) {
  }
}

export const publishDraft = (postId) => async dispatch => {
  try {
    const res = await creatorApi.post(`/posts/${postId}/publish`);

    return res?.data;
  } catch (err) {
  }
}

export const deletePost = id => async dispatch => {
  try {
    dispatch({
      type: DELETE_POST
    });

    await creatorApi.delete(`/posts/${id}/delete`);

    dispatch({
      type: DELETE_POST_SUCCESS,
      payload: id
    });

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: DELETE_POST_FAILED
    });
  }
};

export const getMediaConvertStatus = (postId) => async dispatch => {
  try {
    const { data } = await creatorApi.get(`/posts/${postId}/upload-status`);

    return data?.post;
  } catch (err) {
    const error = err?.response?.data?.error || 'Something went wrong';
    dispatch(setAlert(error, 'danger'));
  }
}

export const getComments = (postId) => async dispatch => {
  try {
    dispatch({
      type: GET_COMMENTS
    })
    const res = await api.get(`posts/${postId}/comment`);

    dispatch({
      type: GET_COMMENTS_SUCCESS,
      payload: { postId, comments: res.data }
    })

  } catch (err) {
    const error = err?.response?.data?.error || 'Something went wrong';
    dispatch({
      type: GET_COMMENTS_FAILED
    })
    dispatch(setAlert(error, 'danger'));
  }
}

export const clearPosts = () => async dispatch => {
  dispatch({
    type: CLEAR_POSTS
  })
}

export const uploadFile = (file, postId, onUploadProgress) => async () => {
  try {
    if (file && file?.video && file?.fileName) {
      const { data: signedData } = await creatorApi.post('/posts/get-signed-url', {
        fileName: file?.fileName,
        fileType: 'video'
      });
      const { signed_request, content_type } = signedData?.data;
      const s3UploadResult = await axios.put(signed_request, file?.video, {
        headers: {
          'x-amz-acl': 'public-read',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': content_type
        },
        transformRequest: [function (data, headers) {
          delete headers.common.Authorization;
          delete headers.common['x-auth-token'];
          delete headers['x-auth-token'];
          return data
        }]
      })

      const {data: signedDataForJson} = await creatorApi.post('/posts/get-signed-url', {
        fileName: `${file?.id}.json`,
        fileType: 'video'
      });
      const videoConfig = {
        srcVideo: `${file?.fileName}`,
        frameCapture: true,
        postId,
        endpoint: SERVER_URL.replace("https://", ""),
        InputRotate: "AUTO",
        jobTemplate_2160p: "openEncode_Ott_2160p_Avc_Aac_16x9_qvbr",
        jobTemplate_1080p: "openEncode_Ott_1080p_Avc_Aac_16x9_qvbr",
        jobTemplate_720p: "openEncode_Ott_720p_Avc_Aac_16x9_qvbr"
      }
      let blob = new Blob([JSON.stringify(videoConfig)], { type: "application/json" });

      const s3UploadResultForJson = await axios.put(signedDataForJson.data.signed_request, blob, {
        headers: {
          'x-amz-acl': 'public-read',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': signedDataForJson.data.content_type
        },
        transformRequest: [function (data, headers) {
          delete headers.common.Authorization;
          delete headers.common['x-auth-token'];
          delete headers['x-auth-token'];
          return data
        }]
      })
    } else if (file?.image && file?.fileName) {
      const { data: signedData } = await creatorApi.post('/posts/get-signed-url', {
        fileName: file?.fileName,
        fileType: 'image'
      });
      const { signed_request, content_type, url } = signedData?.data;

      const s3UploadResponse = axios.put(signed_request, file?.image, {
        headers: {
          'x-amz-acl': 'public-read',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': content_type
        },
        transformRequest: [function (data, headers) {
          delete headers.common.Authorization;
          delete headers.common['x-auth-token'];
          delete headers['x-auth-token'];
          return data
        }],
        onUploadProgress
      });

      return {...s3UploadResponse, url };
    }
  } catch (err) {
  }

}

export const getEvents = () => async () => {
  try {
    const { data } = await creatorApi.get('/users/events?type=event') || {};

    return data?.events;
  } catch (err) {
  }
}

export const getRooms = () => async () => {
  try {
    const { data } = await creatorApi.get('/users/events?type=room') || {};

    return data?.events;
  } catch (err) {
  }
}

export const getDrafts = (id) => async () => {
  try {
    let url = `/events/${id}/draft`;

    const { data } = await creatorApi.get(url);

    return data?.data;
  } catch (err) {
  }
}
