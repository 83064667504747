import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import "typeface-roboto";
import ReactModal from "react-modal";

import Navbar from './components/layout/Navbar';
import Routes from './components/routing/Routes';
import NavFooter from './components/layout/NavFooter';

import { SocketUtil } from './utils/socket';
import init from './init';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { setLayout } from './actions/layout';
import { MoralisProvider } from 'react-moralis';
SocketUtil.getInstance();
init();

ReactModal.defaultStyles.overlay.zIndex = 1072; // same as bootstrap modal
ReactModal.defaultStyles.overlay.backgroundColor = "rgba(40, 40, 40, 0.8)";
ReactModal.defaultStyles.content = {};

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  window.addEventListener('click', () => {
    store.dispatch(setLayout({ showNotification: false}))
  })
  const APP_ID = process.env.REACT_APP_MORALIS.split(',')[0];
  const SERVER_URL = process.env.REACT_APP_MORALIS.split(',')[1];
  
  return (
    <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
      <Provider store={store}>
        <Router>
          <Fragment>
            <Navbar />
            <Routes />
            <NavFooter />
          </Fragment>
        </Router>
      </Provider>
    </MoralisProvider>
  );
};


export default App;
