import React, { useEffect, useState, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCommentDots, faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

import Notification from "../notification";

import { isAuthenticated as isLoggedIn } from '../../utils/auth';

import { setMessageCount } from "../../actions/message";
import { GetStreamUtil } from '../../utils/getstream';
import { setLayout } from '../../actions/layout';

import logoImg from "../../assets/images/showcase-black.png";
import logoImgInverse from "../../assets/images/showcase-white.png";
import crBox from '../../assets/images/crbox.svg';
import colorBox from '../../assets/images/colorbox.svg';

import "./navbar.scss";

const navPaths = [
  '/',
  '/notifications',
  '/rooms',
  '/messaging',
  '/dashboard'
]

const Navbar = ({
  auth: {
    isAuthenticated,
    loading,
    user
  },
  message: {
    totalUnreadCount,
    unreadChannels
  },
  setMessageCount,
  layout,
  setLayout
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [showMessageNotification, setMessageNotification] = useState(false);
  const [isGetStreamInitialized, setIsGetStreamInitialized] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const location = useLocation();
  const [showAuthLinks, setShowAuthLinks] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNotification = () => {
    const bool = !showNotification;

    setShowNotification(bool);
  }

  useEffect(() => {
    setShowAuthLinks(isLoggedIn());
  }, [])

  useEffect(() => {
    setLayout({ showNotification });
  }, [showNotification]);

  useEffect(() => {
    if (!layout?.showNotification) {
      setShowNotification(false);
    }
  }, [layout, layout?.showNotification]);

  useEffect(() => {
    if (navPaths.includes(location.pathname)) {
      setActivePage(location.pathname.split('/')[1] || null);
    }
    const slugs = location.pathname.split('/');

    if (slugs.length >= 3 && slugs[2] === 'venue') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

  }, [location]);

  useEffect(() => {
    if (isAuthenticated && user?._id) {
      setUser(user);
    }
    setShowAuthLinks(isLoggedIn());
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isGetStreamInitialized) subscribeToGetStreamEvents();
  }, [isGetStreamInitialized])

  useEffect(() => {
    if (totalUnreadCount) {
      setMessageNotification(true);
    } else {
      setMessageNotification(false);
    }
  }, [totalUnreadCount])

  const subscribeToGetStreamEvents = () => {
    if (GetStreamUtil.getInstance().chatClient) {
      const { user } = GetStreamUtil.getInstance().chatClient;

      setMessageCount({
        totalUnreadCount: user?.total_unread_count,
        unreadChannels: user?.unread_channels
      })
    }
    GetStreamUtil.getInstance().chatClient.on(event => {
      if (event?.type === 'notification.message_new'
        || event?.type === 'notification.mark_read'
        || event?.type === 'message.new') {
        setMessageCount({
          totalUnreadCount: event?.total_unread_count,
          unreadChannels: event?.unread_channels
        })
      }
    })
  }

  const setUser = async (user) => {
    if (!GetStreamUtil.getInstance().isUserSet && !GetStreamUtil.getInstance().initializing) {
      await GetStreamUtil.getInstance().setUser({
        id: user?._id,
        name: user?.name,
        image: user?.avatar,
        gs_token: user?.gs_token
      });
    }
    setIsGetStreamInitialized(true)
  }


  const authLinksSidebar = React.useMemo(() => (
    <React.Fragment>
      <ul className={`nav grid-col-5 ${isMenuOpen && 'mobile-nav'} auth-nav-sidebar`} onClick={toggleMenu}>
        <li className="nav-item">
          <Link to="/">
            <FontAwesomeIcon icon={faHome} /> Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to='/notifications'>
            <FontAwesomeIcon icon={faBell} /> Notifications
          </Link>
        </li>
        <li className="nav-item">
          <Link to='/rooms'>
            <img className="cr-box" src={crBox}></img> Content Rooms
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/messaging" style={{ "position": "relative" }}>
            <FontAwesomeIcon icon={faCommentDots} /> Message
            {showMessageNotification ? <span className="notification-circle">
              {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
            </span> : ''}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faUser} /> Me
          </Link>
        </li>
      </ul>
      <div className={`nav-backdrop ${isMenuOpen && 'show'}`} onClick={toggleMenu}></div>
    </React.Fragment>
  ), [isMenuOpen, showMessageNotification, totalUnreadCount]);

  const authLinks = React.useMemo(() => (
    <React.Fragment>
      <ul className={'nav grid-col-5 auth-nav-default'}>
        <li className="nav-item">
          <Link to="/" className={!activePage && 'active'}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </li>
        <li className="nav-item">
          <Link onClick={()=>toggleNotification()} className={showNotification ? 'active' : ''}>
            <FontAwesomeIcon icon={faBell} />
          </Link>
        </li>
        <li className="nav-item">
          <Link to='/rooms'>
            <img className="cr-box" src={activePage === 'rooms' ? colorBox : crBox}></img>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/messaging" style={{ "position": "relative" }} className={activePage === 'messaging' && 'active'}>
            <FontAwesomeIcon icon={faCommentDots} />
            {showMessageNotification ? <span className="notification-circle">
              {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
            </span> : ''}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard" className={activePage === 'dashboard' && 'active'}>
            <FontAwesomeIcon icon={faUser} />
          </Link>
        </li>
      </ul>
    </React.Fragment>
  ), [showMessageNotification, activePage, totalUnreadCount, showNotification]);

  const guestLinks = React.useMemo(() => (
    <React.Fragment>
      <ul className="nav guest-nav-default">
        <li className="nav-item">
          <Link to='/login'>
            <button className="btn btn-outline-dark rounded-pill font-weight-bold">Log In / Sign Up</button>
          </Link>
        </li>

      </ul>
    </React.Fragment>
  ), []);

  return (
    <Fragment>
      <div className={`${isDarkMode ? 'nav-wrapper fixed-top navbar-dark' : 'nav-wrapper fixed-top navbar-default'} ${layout?.hideNavbarOnMobile && 'hide-navbar-on-mobile'}`}>
        <nav className="navbar container">
          {isDarkMode
            ? <div className="dark-logo-container position-relative">
              <img className='logo logo-dark' src={logoImgInverse} alt="CR" draggable={false} />
            </div>
            : <Link to={isDarkMode ? "" : "/"} className="position-relative">
              <img className='logo' src={logoImg} alt="CR" draggable={false} />
          </Link>}
          {showAuthLinks && authLinksSidebar}
          {showAuthLinks && authLinks}
          {!showAuthLinks && guestLinks}
          <div className={`menu-toggle ${isMenuOpen && 'is-active'}`} id="mobile-menu" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
        {showNotification && <Notification toggleNotification={toggleNotification}/>}
      </div>
    </Fragment>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  layout: state.layout
});

export default connect(mapStateToProps, {
  setMessageCount,
  setLayout
})(Navbar);
