import * as storage from './storage';
import { ACCESS_TOKEN, REFRESH_TOKEN, DID_TOKEN } from '../constants/storage';

/**
 * Get access token from storage.
 *
 * @returns {string}
 */
export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

/**
 * Set access token to storage.
 *
 * @param {string} accessToken
 */
export function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
}

/**
 * Get refresh token from storage.
 *
 * @returns {string}
 */
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

/**
 * Set refresh token to storage.
 *
 * @param {string} refreshToken
 *
 * @returns {string}
 */
export function setRefreshToken(refreshToken) {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function setConnectorId(value) {
  localStorage.setItem('connectorId', value);
}

export function getConnectorId() {
  return localStorage.getItem('connectorId');
}


/**
 * Clear tokens.
 */
export function clear() {
  localStorage.clear();
}

export function clearWalletConnect() {
  localStorage.removeItem('walletconnect');
}
