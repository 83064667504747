import {
  SET_LAYOUT
} from '../actions/types';

const initialState = {
  isBottomBarHidden: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LAYOUT: {
      return {
        ...state,
        ...payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
