import {
	GET_EVENT,
	GET_EVENT_SUCCESS,
	GET_EVENTS,
	EVENT_ERROR,
	UPDATE_EVENT,
	CLEAR_EVENT,
	BUY_EVENT,
	GET_VENUE,
	GET_VENUE_SUCCESS,
	GET_VENUE_FAILED,
	POST_PAYMENT,
	POST_PAYMENT_SUCCESS,
	POST_PAYMENT_FAILED,
	DONATE_AMOUNT,
	DONATE_AMOUNT_SUCCESS,
	DONATE_AMOUNT_FAILED,
} from '../actions/types';

const initialState = {
	event: { donationInProgress: false, donationSuccess: false },
	events: [],
	loading: false,
	error: {},
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_EVENT:
			return { ...state, loading: true};
		case GET_EVENT_SUCCESS:
		case UPDATE_EVENT:
			return {
				...state,
				event: payload,
				venue: null,
				loading: false,
			};
		case GET_EVENTS:
			return {
				...state,
				events: payload,
				loading: false,
			};
		case EVENT_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
				event: null,
			};
		case CLEAR_EVENT:
			return {
				...state,
				event: null,
			};
		case BUY_EVENT:
			return {
				state,
			};
		case GET_VENUE:
			return {
				...state,
				loading: true,
				venue: null,
				error: null,
			};
		case GET_VENUE_SUCCESS:
			return {
				...state,
				loading: false,
				venue: payload,
				error: null,
			};
		case GET_VENUE_FAILED:
			return {
				...state,
				loading: false,
				venue: null,
				error: payload,
			};
		case DONATE_AMOUNT:
			console.log('Donate Amount');
			return {
				...state,
				event: {
					...state.event,
					donationInProgress: true,
					donationSuccess: false,
				},
			};
		case DONATE_AMOUNT_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					donationInProgress: false,
					donationSuccess: true,
				},
			};
		case DONATE_AMOUNT_FAILED:
			return {
				...state,
				event: {
					...state.event,
					donationInProgress: false,
					donationSuccess: false,
				},
				error: payload,
			};
		default:
			return state;
	}
}
