import React, { Fragment } from 'react';
import './spinner.scss';

export default () => (
  <Fragment>
    <div className="spinner-container">
      <div className="loadingio-spinner-dual-ring-s0cequy1keb"><div className="ldio-hmispynwpe">
        <div></div><div><div></div></div>
      </div></div>
    </div>
  </Fragment>
);
