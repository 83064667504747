import {
  SET_MESSAGE_COUNT
} from '../actions/types';

const initialState = {
  totalUnreadCount: 0,
  unreadChannels: 0
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE_COUNT:
      return {
        ...state,
        totalUnreadCount: payload?.totalUnreadCount,
        unreadChannels: payload?.unreadChannels
      }
    default:
      return state;
  }
}
