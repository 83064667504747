import { io } from 'socket.io-client';
import store from '../store';
import { setNotification } from '../actions/notification';

let instance = null;

class SocketUtil {
	constructor() {
		if(instance) {
			return instance;
		}

		this.socket = io(process.env.REACT_APP_SERVER_URL);
		this.creatorSocket = io(process.env.REACT_APP_CREATOR_SERVER_URL)
		instance = this;
		this.listenEvents();
	}

	listenEvents() {
		this.socket.onAny((event, ...args) => {
			store.dispatch(setNotification(event));
		});

		this.creatorSocket.onAny((event, ...args) => {
			store.dispatch(setNotification(event));
		})
	}

	static getInstance() {
		return new SocketUtil();
	}
}

export { SocketUtil };
