import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CREATOR_SERVER_URL = process.env.REACT_APP_CREATOR_SERVER_URL;

const api = axios.create({
	baseURL: `${SERVER_URL}/api`,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	},
});

const authApi = axios.create({
  baseURL: `${SERVER_URL}/api/auth`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

const creatorApi = axios.create({
  baseURL: `${CREATOR_SERVER_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})


export { api as default, authApi, creatorApi};
