import {
  GET_ROOM_PENDING,
  GET_ROOM_FULFILLED,
  GET_ROOM_REJECTED,
  GET_ROOMS_PENDING,
  GET_ROOMS_FULFILLED,
  GET_ROOMS_REJECTED,
  UPDATE_CALL_URL_PENDING,
  UPDATE_CALL_URL_REJECTED,
  UPDATE_CALL_URL_FULFILLED,
  POST_PAYMENT,
  POST_PAYMENT_SUCCESS,
  POST_PAYMENT_FAILED,
  CANCEL_SUBSCRIPTION_PENDING,
  CANCEL_SUBSCRIPTION_REJECTED,
  CANCEL_SUBSCRIPTION_FULFILLED,
  CREATE_SUBSCRIPTION_PENDING,
  CREATE_SUBSCRIPTION_REJECTED,
  CREATE_SUBSCRIPTION_FULFILLED,
  UPLOAD_ROOM_AVATAR_PENDING,
  UPLOAD_ROOM_AVATAR_REJECTED,
  UPLOAD_ROOM_AVATAR_FULFILLED,
  UPLOAD_ROOM_BANNER_PENDING,
  UPLOAD_ROOM_BANNER_REJECTED,
  UPLOAD_ROOM_BANNER_FULFILLED,
  UPDATE_ROOM_SOCIALS_PENDING,
  UPDATE_ROOM_SOCIALS_REJECTED,
  UPDATE_ROOM_SOCIALS_FULFILLED,
  CLEAR_ROOM,
  GET_ALL_EVENTS_PENDING,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_FAILED
} from '../actions/types';

const initialState = {
  room: null,
  rooms: [],
  loading: true,
  error: null,
  updatingCallUrl: false,
  allEvents: [],
  fetchingAllEvents: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ROOMS_PENDING:
      return {
        ...state,
        loading: true
      }
    case GET_ROOMS_FULFILLED:
      return {
        ...state,
        loading: false,
        rooms: payload
      }
    case GET_ROOMS_REJECTED:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case GET_ROOM_PENDING:
      return {
        ...state,
        loading: true
      }
    case GET_ROOM_FULFILLED:
      return {
        ...state,
        loading: false,
        room: payload
      }
    case GET_ROOM_REJECTED:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case CLEAR_ROOM:
      return {
        ...state,
        room: null
      }
    case UPDATE_CALL_URL_PENDING:
      return {
        ...state,
        updatingCallUrl: true
      }
    case UPDATE_CALL_URL_FULFILLED:
      return {
        ...state,
        room: {
          ...state.room,
          callUrl: payload.url
        },
        updatingCallUrl: false
      }
    case UPDATE_CALL_URL_REJECTED:
      return {
        ...state,
        updateingCallUrl: false
      }
    case POST_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case POST_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          purchased: true,
          showUnsubscribe: payload?.gateway === 'stripe' ? true : false
        },
      };
    case POST_PAYMENT_FAILED:
      return {
        ...state,
        loading: false
      };
    case CREATE_SUBSCRIPTION_PENDING:
      return {
        ...state,
        loading: true
      }
    case CREATE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        loading: false,
        room: {
          ...state.room,
          purchased: true
        }
      }
    case CREATE_SUBSCRIPTION_REJECTED:
      return {
        ...state,
        loading: false
      }
    case CANCEL_SUBSCRIPTION_PENDING:
      return {
        ...state,
        loading: true
      }
    case CANCEL_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        loading: false,
        room: {
          ...state,
          status: 'cancelled'
        }
      }
    case CANCEL_SUBSCRIPTION_REJECTED:
      return {
        ...state,
        loading: false
      }
    case UPLOAD_ROOM_AVATAR_PENDING:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingAvatar: true,
          uploadAvatarSuccess: false
        }
      }
    case UPLOAD_ROOM_AVATAR_FULFILLED:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingAvatar: false,
          uploadAvatarSuccess: true,
          avatar: payload
        }
      }
    case UPLOAD_ROOM_AVATAR_REJECTED:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingAvatar: false,
          uploadAvatarSuccess: false
        }
      }
    case UPLOAD_ROOM_BANNER_PENDING:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingBanner: true,
          uploadBannerSuccess: false
        }
      }
    case UPLOAD_ROOM_BANNER_FULFILLED:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingBanner: false,
          uploadBannerSuccess: true,
          bannerImgUrl: payload
        }
      }
    case UPLOAD_ROOM_BANNER_REJECTED:
      return {
        ...state,
        room: {
          ...state.room,
          uploadingBanner: false,
          uploadBannerSuccess: false
        }
      }
    case UPDATE_ROOM_SOCIALS_PENDING:
      return {
        ...state,
        room: {
          ...state.room,
          updatingSocials: true,
          updateSocialsSucess: false,
        }
      }
    case UPDATE_ROOM_SOCIALS_FULFILLED:
      return {
        ...state,
        room: {
          ...state.room,
          updatingSocials: false,
          updateSocialsSucess: true,
          social: payload
        }
      }
    case UPDATE_ROOM_SOCIALS_REJECTED:
      return {
        ...state,
        room: {
          ...state.room,
          updatingSocials: false,
          updateSocialsSucess: false
        }
      }
    case GET_ALL_EVENTS_PENDING:
      return {
        ...state,
        fetchingAllEvents: true
      }
    case GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingAllEvents: false,
        allEvents: payload
      }
    case GET_ALL_EVENTS_FAILED:
      return {
        ...state,
        fetchingAllEvents: false
      }
    default:
      return state;
  }
}
