export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const GET_PROFILE_PENDING = 'GET_PROFILE_PENDING';
export const GET_PROFILE = 'GET_PROFILE';
export const CONNECT_STRIPE = 'CONNECT_STRIPE';
export const CONNECT_STRIPE_FAIL = 'CONNECT_STRIPE_FAIL';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_SOCIAL_MEDIA = 'UPDATE_SOCIAL_MEDIA';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
//addition
export const GET_USER_POSTS = 'GET_USER_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const GET_ROOM_POSTS = 'GET_ROOM_POSTS';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const BUY_EVENT = 'BUY_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const EVENT_ERROR = 'EVENT_ERROR';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const CLEAR_EVENT = 'CLEAR_EVENT';

export const GET_EVENT_POSTS = 'GET_EVENT_POSTS';
export const GET_UPDATED_POST = 'GET_UPDATED_POST';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';

export const GET_VENUE = 'GET_VENUE';
export const GET_VENUE_SUCCESS = 'GET_VENUE_SUCCESS';
export const GET_VENUE_FAILED = 'GET_VENUE_FAILED';
export const POST_PAYMENT = 'POST_PAYMENT';
export const POST_PAYMENT_SUCCESS = 'POST_PAYMEN_SUCCESS';
export const POST_PAYMENT_FAILED = 'POST_PAYMENY_FAILED';

export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAIL = 'IMAGE_UPLOAD_FAIL';

export const DONATE_AMOUNT = 'DONATE_AMOUNT';
export const DONATE_AMOUNT_SUCCESS = 'DONATE_AMOUNT_SUCCESS';
export const DONATE_AMOUNT_FAILED = 'DONATE_AMOUNT_FAILED';

export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCESS';
export const UPDATE_PAYMENT_METHOD_FAILED = 'UPDATE_PAYMENT_METHOD_FAILED';

export const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL';
export const RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILED = 'VERIFY_CODE_FAILED';

export const GET_POST_DRAFTS = 'GET_POST_DRAFTS';
export const GET_POST_DRAFTS_SUCCESS = 'GET_POST_DRAFTS_SUCCESS';
export const GET_POST_DRAFTS_FAILED = 'GET_POST_DRAFTS_FAILED';

export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED';

export const SAVE_DRAFT = 'SAVE_DRAFT';
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS';
export const SAVE_DRAFT_FAILED = 'SAVE_DRAFT_FAILED';

export const PUBLISH_DRAFT = 'PUBLISH_DRAFT';
export const PUBLISH_DRAFT_SUCCESS = 'PUBLISH_DRAFT_SUCCESS';
export const PUBLISH_DRAFT_FAILED = 'PUBLISH_DRAFT_FAILED';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILED = 'DELETE_POST_FAILED';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED';

export const CLEAR_POSTS = 'CLEAR_POSTS';

export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const CLEAR_DEVICES_SUCCESS = 'CLEAR_DEVICES_SUCCESS';
export const CLEAR_DEVICES_FAILED = 'CLEAR_DEVICES_FAILED';

export const SIGNUP_WITH_MM = 'SIGNUP_WITH_MM';
export const SIGNUP_WITH_MM_SUCCESS = 'SIGNUP_WITH_MM_SUCCESS';
export const SIGNUP_WITH_MM_FAILED = 'SIGNUP_WITH_MM_FAILED';

export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT';

export const SET_LAYOUT = 'SET_LAYOUT';

export const GET_EVENT_PAYMENTS = 'GET_EVENT_PAYMENTS';
export const GET_EVENT_PAYMENTS_PENDING = 'GET_EVENT_PAYMENTS_PENDING';
export const GET_EVENT_PAYMENTS_REJECTED = 'GET_EVENT_PAYMENTS_REJECTED';
export const GET_EVENT_PAYMENTS_FULFILLED = 'GET_EVENT_PAYMENTS_FULFILLED';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_PENDING = 'GET_BALANCE_PENDING';
export const GET_BALANCE_REJECTED = 'GET_BALANCE_REJECTED';
export const GET_BALANCE_FULFILLED = 'GET_BALANCE_FULFILLED';
export const GOTO_STRIPE_DASHBOARD = 'GOTO_STRIPE_DASHBOARD';
export const GOTO_STRIPE_DASHBOARD_PENDING = 'GOTO_STRIPE_DASHBOARD_PENDING';
export const GOTO_STRIPE_DASHBOARD_FULFILLED = 'GOTO_STRIPE_DASHBOARD_FULFILLED';
export const GOTO_STRIPE_DASHBOARD_REJECTED = 'GOTO_STRIPE_DASHBOARD_REJECTED';
export const PAYOUT = 'PAYOUT';
export const PAYOUT_PENDING = 'PAYOUT_PENDING';
export const PAYOUT_FULFILLED = 'PAYOUT_FULFILLED';
export const PAYOUT_REJECTED = 'PAYOUT_REJECTED';

export const GET_ROOMS_PENDING = 'GET_ROOMS_PENDING';
export const GET_ROOMS_REJECTED = 'GET_ROOMS_REJECTED';
export const GET_ROOMS_FULFILLED = 'GET_ROOMS_FULFILLED';

export const GET_ROOM_PENDING = 'GET_ROOM_PENDING';
export const GET_ROOM_REJECTED = 'GET_ROOM_REJECTED';
export const GET_ROOM_FULFILLED = 'GET_ROOM_FULFILLED';

export const UPDATE_CALL_URL_PENDING = 'UPDATE_CALL_URL_PENDING';
export const UPDATE_CALL_URL_REJECTED = 'UPDATE_CALL_URL_REJECTED';
export const UPDATE_CALL_URL_FULFILLED = 'UPDATE_CALL_URL_FULFILLED';

export const CREATE_SUBSCRIPTION_PENDING = 'CREATE_SUBSCRIPTION_PENDING';
export const CREATE_SUBSCRIPTION_REJECTED = 'CREATE_SUBSCRIPTION_REJECTED';
export const CREATE_SUBSCRIPTION_FULFILLED = 'CREATE_SUBSCRIPTION_FULFILLED';

export const CANCEL_SUBSCRIPTION_PENDING = 'CANCEL_SUBSCRIPTION_PENDING';
export const CANCEL_SUBSCRIPTION_REJECTED = 'CANCEL_SUBSCRIPTION_REJECTED';
export const CANCEL_SUBSCRIPTION_FULFILLED = 'CANCEL_SUBSCRIPTION_FULFILLED';

export const CLEAR_ERROR = 'CLEAR_ERROR';

export const SET_USER_EMAIL_VERIFIED_PENDING = 'SET_USER_EMAIL_VERIFIED_PENDING';
export const SET_USER_EMAIL_VERIFIED_REJECTED = 'SET_USER_EMAIL_VERIFIED_REJECTED';
export const SET_USER_EMAIL_VERIFIED_FULFILLED = 'SET_USER_EMAIL_VERIFIED_FULFILLED';

export const UPLOAD_ROOM_AVATAR_PENDING = 'UPLOAD_ROOM_AVATAR_PENDING';
export const UPLOAD_ROOM_AVATAR_REJECTED = 'UPLOAD_ROOM_AVATAR_REJECTED';
export const UPLOAD_ROOM_AVATAR_FULFILLED = 'UPLOAD_ROOM_AVATAR_FULFILLED';

export const UPLOAD_ROOM_BANNER_PENDING = 'UPLOAD_ROOM_BANNER_PENDING';
export const UPLOAD_ROOM_BANNER_REJECTED = 'UPLOAD_ROOM_BANNER_REJECTED';
export const UPLOAD_ROOM_BANNER_FULFILLED = 'UPLOAD_ROOM_BANNER_FULFILLED';

export const UPDATE_ROOM_SOCIALS_PENDING = 'UPDATE_ROOM_SOCIALS_PENDING';
export const UPDATE_ROOM_SOCIALS_REJECTED = 'UPDATE_ROOM_SOCIALS_REJECTED';
export const UPDATE_ROOM_SOCIALS_FULFILLED = 'UPDATE_ROOM_SOCIAL_FULFILLED';

export const CLEAR_ROOM = 'CLEAR_ROOM';

export const GET_ALL_EVENTS_PENDING = 'GET_ALL_EVENTS_PENDING';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILED = 'GET_ALL_EVENTS_FAILED';

export const VALIDATE_MORALIS_SESSION_PENDING = 'VALIDATE_MORALIS_SESSION_PENDING';
export const VALIDATE_MORALIS_SESSION_SUCCESS = 'VALIDATE_MORALIS_SESSION_SUCCESS';
export const VALIDATE_MORALIS_SESSION_FAILED = 'VALIDATE_MORALIS_SESSION_FAILED';

export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const CHECK_USER_EXISTS_PENDING = 'CHECK_USER_EXISTS_PENDING';
export const CHECK_USER_EXISTS_SUCCESS = 'CHECK_USER_EXISTS_SUCCESS';
export const CHECK_USER_EXISTS_FAILED = 'CHECK_USER_EXISTS_FAILED';

export const LOAD_USER_PENDING = 'LOAD_USER_PENDING';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';