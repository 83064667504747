import { v4 as uuidv4 } from 'uuid';
import {
	REMOVE_NOTIFICATION,
	SET_NOTIFICATION
} from './types';
import {
	getUpdatedPost
} from './post';

export const setNotification = (data) => async (dispatch) => {
	const id = uuidv4();
	dispatch(getUpdatedPost(data?.id))
	if(data.type === 'POST') {
		switch(data.action) {
			case 'comment':
				dispatch({
					type: SET_NOTIFICATION,
					payload: {
						id,
						route: `/${data?.eventRouteKey}/feed/post/${data?.id}`,
						text: `${data?.user} commented on a post`,
						type: 'comment'
					}
				});
				break;
				case 'comment_delete':
					dispatch({
						type: SET_NOTIFICATION,
						payload: {
							id,
							route: `/${data?.eventRouteKey}/feed/post/${data?.id}`,
							text: `${data?.user} deleted a comment on a post`,
							type: 'comment'
						}
					});
					break;
			case 'like':
				dispatch({
					type: SET_NOTIFICATION,
					payload: {
						id,
						route: `/${data?.eventRouteKey}/feed/post/${data?.id}`,
						text: `${data?.user} liked a post`,
						type: 'like'
					}
				});
				break;
			case 'create':
				dispatch({
					type: SET_NOTIFICATION,
					payload: {
						id,
						route: `/${data?.eventRouteKey}/feed/post/${data?.id}`,
						text: `${data?.user} created a post`,
						type: 'create'
					}
				});
				
				break;
		}

		setTimeout(() => dispatch({ type: REMOVE_NOTIFICATION, payload: id }), 5000);
	}
}

export const removeNotification = (id) =>  async (dispatch) => {
	dispatch({
		type: REMOVE_NOTIFICATION,
		payload: id
	})
}
