import {
  GET_POSTS,
  GET_USER_POSTS,
  GET_ROOM_POSTS,
  GET_EVENT_POSTS,
  GET_UPDATED_POST,
  POST_ERROR,
  UPDATE_LIKES,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_DRAFTS,
  GET_POST_DRAFTS_SUCCESS,
  GET_POST_DRAFTS_FAILED,
  CREATE_POST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILED,
  PUBLISH_DRAFT,
  PUBLISH_DRAFT_SUCCESS,
  PUBLISH_DRAFT_FAILED,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED,
  SAVE_DRAFT,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_FAILED,
  GET_COMMENTS,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILED,
  CLEAR_POSTS
} from '../actions/types';

const initialState = {
  postsPaginationDetails: {
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: null,
    prevPage: null,
    page: 1,
    totalDocs: 0,
    totalPages: 0
  },
  posts: [],
  post: null,
  loading: false,
  error: {},
  drafts: [],
  fetchingDrafts: false,
  savingDraft: false,
  deletingDraft: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let postDetail = null;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: payload,
        loading: false
      };
    case GET_POST:
      return {
        ...state,
        post: payload,
        loading: false
      };
    case GET_UPDATED_POST:
      let post = { ...state.post } || null;
      let oldPosts = [...state.posts.map(x => { return { ...x } })];

      if (post && post._id === payload._id) {
        post = { ...state.post, ...payload }
      }

      const index = oldPosts.findIndex(x => x._id === payload._id);

      if (index >= 0) {
        oldPosts = [...oldPosts.map(x => {
          if (x._id === payload._id) {
            x = { ...x, ...payload }
          }

          return x;
        })]
      } else {
        oldPosts = [{ ...payload }, ...oldPosts];
      }

      return {
        ...state,
        post: { ...post },
        posts: [...oldPosts],
        loading: false
      }
    case GET_USER_POSTS:
      return {
        ...state,
        posts: payload,
        loading: false
      };
    case GET_ROOM_POSTS:
      return {
        ...state,
        posts: payload,
        loading: false
      };
    case GET_EVENT_POSTS:
      const posts = payload?.posts || [];
      const {
        hasNextPage = false,
        hasPrevPage = false,
        limit = 0,
        nextPage = null,
        prevPage = null,
        page = 1,
        totalDocs = 0,
        totalPages = 0
      } = payload;
      postDetail = { ...state.post } || null;
      if (postDetail) {
        posts.forEach(item => {
          if (item._id === postDetail._id) {
            postDetail = { ...postDetail, ...item };
          }
        })
      }

      return {
        ...state,
        posts: page === 1 ? [...posts] : [...state.posts, ...posts],
        post: postDetail,
        postsPaginationDetails: {
          hasNextPage,
          hasPrevPage,
          limit,
          nextPage,
          prevPage,
          page,
          totalDocs,
          totalPages
        },
        loading: false
      };
    case CLEAR_POSTS:
      return {
        ...state,
        posts: []
      }
    case ADD_POST:
      return {
        ...state,
        posts: [payload, ...state.posts],
        loading: false
      };
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case UPDATE_LIKES:
      postDetail = { ...state.post } || null;
      let allPostsCopy = [...state.posts.map(x => { return { ...x } })];

      if (payload?.postId && payload?.commentId && payload?.subCommentId) {
        if (postDetail._id === payload?.postId && Array.isArray(postDetail?.comments)) {
          postDetail.comments.forEach(comment => {
            if (comment._id === payload?.commentId && Array.isArray(comment?.subComments) && comment?.subComments.length > 0) {
              comment.subComments.forEach(subComment => {
                if (subComment._id === payload?.subCommentId) {
                  const upvoteIdx = subComment?.upvotes.findIndex(x => x === payload?.userId);
                  const downvoteIdx = subComment?.downvotes.findIndex(x => x === payload?.userId);
                  if (payload?.type === 'upvote') {
                    if (upvoteIdx >= 0) {
                      subComment.upvotes.splice(upvoteIdx, 1);
                    } else {
                      subComment.upvotes.push(payload?.userId);
                    }
                    if (downvoteIdx >= 0) {
                      subComment.downvotes.splice(downvoteIdx, 1);
                    }
                  } else if (payload?.type === 'downvote') {
                    if (upvoteIdx >= 0) {
                      subComment.upvotes.splice(upvoteIdx, 1);
                    }
                    if (downvoteIdx >= 0) {
                      subComment.downvotes.splice(downvoteIdx, 1);
                    } else {
                      subComment.downvotes.push(payload?.userId);
                    }
                  }
                  subComment.totalVotes = subComment?.upvotes.length - subComment?.downvotes.length;
                }
              })
            }
          })
        }
      } else if (payload?.postId && payload?.commentId) {
        if (postDetail._id === payload?.postId && Array.isArray(postDetail?.comments)) {
          postDetail.comments.forEach(comment => {
            if (comment?._id === payload?.commentId) {
              const upvoteIdx = comment?.upvotes.findIndex(x => x === payload?.userId);
              const downvoteIdx = comment?.downvotes.findIndex(x => x === payload?.userId);
              if (payload?.type === 'upvote') {
                if (upvoteIdx >= 0) {
                  comment.upvotes.splice(upvoteIdx, 1);
                } else {
                  comment.upvotes.push(payload?.userId);
                }
                if (downvoteIdx >= 0) {
                  comment.downvotes.splice(downvoteIdx, 1);
                }
              } else if (payload?.type === 'downvote') {
                if (upvoteIdx >= 0) {
                  comment.upvotes.splice(upvoteIdx, 1);
                }
                if (downvoteIdx >= 0) {
                  comment.downvotes.splice(downvoteIdx, 1);
                } else {
                  comment.downvotes.push(payload?.userId);
                }
              }
              comment.totalVotes = comment?.upvotes.length - comment?.downvotes.length;
            }
          })
        }
      } else if (payload?.postId) {
        if (postDetail._id === payload?.postId) {
          const upvoteIdx = postDetail?.upvotes.findIndex(x => x === payload?.userId);
          const downvoteIdx = postDetail?.downvotes.findIndex(x => x === payload?.userId);
          if (payload?.type === 'upvote') {
            if (upvoteIdx >= 0) {
              postDetail.upvotes.splice(upvoteIdx, 1);
            } else {
              postDetail.upvotes.push(payload?.userId);
            }
            if (downvoteIdx >= 0) {
              postDetail.downvotes.splice(downvoteIdx, 1);
            }
          } else if (payload?.type === 'downvote') {
            if (upvoteIdx >= 0) {
              postDetail.upvotes.splice(upvoteIdx, 1);
            }
            if (downvoteIdx >= 0) {
              postDetail.downvotes.splice(downvoteIdx, 1);
            } else {
              postDetail.downvotes.push(payload?.userId);
            }
          }
          postDetail.totalVotes = postDetail?.upvotes.length - postDetail?.downvotes.length;
        }
        allPostsCopy.forEach(post => {
          if (post._id === payload?.postId) {
            const upvoteIdx = post?.upvotes.findIndex(x => x === payload?.userId);
            const downvoteIdx = post?.downvotes.findIndex(x => x === payload?.userId);
            if (payload?.type === 'upvote') {
              if (upvoteIdx >= 0) {
                post.upvotes.splice(upvoteIdx, 1);
              } else {
                post.upvotes.push(payload?.userId);
              }
              if (downvoteIdx >= 0) {
                post.downvotes.splice(downvoteIdx, 1);
              }
            } else if (payload?.type === 'downvote') {
              if (upvoteIdx >= 0) {
                post.upvotes.splice(upvoteIdx, 1);
              }
              if (downvoteIdx >= 0) {
                post.downvotes.splice(downvoteIdx, 1);
              } else {
                post.downvotes.push(payload?.userId);
              }
            }
            post.totalVotes = post?.upvotes.length - post?.downvotes.length;
          }
        })
      } else {
        return { ...state };
      }

      return {
        ...state,
        posts: allPostsCopy,
        post: postDetail,
        loading: false
      };
    case ADD_COMMENT:
      postDetail = { ...state.post } || null;

      if (postDetail && postDetail._id === payload.postId) postDetail = { ...postDetail, comments: [...payload.comments] }
      const postsCopy = [...state.posts.map(post => ({ ...post }))];
      postsCopy && postsCopy.length && postsCopy.forEach(post => {
        if (payload.postId === post._id) {
          post.comments = payload.comments;
        }
      })
      return {
        ...state,
        posts: postsCopy,
        post: postDetail,
        loading: false
      };
    case REMOVE_COMMENT:
      postDetail = { ...state.post } || null;

      if (postDetail && postDetail._id === payload.postId) {
        if (payload.commentId && payload.parentCommentId) {
          postDetail.comments.forEach(comment => {
            if (comment._id === payload.parentCommentId &&
              Array.isArray(comment.subComments) &&
              comment.subComments.length > 0) {
              comment.subComments = comment.subComments.filter(subComment => subComment._id !== payload.commentId);
            }
          })
          postDetail = {
            ...postDetail,
            comments: [...postDetail.comments.map(comment => { return {...comment}})]
          }
        } else if (payload.commentId) {
          postDetail = {
            ...postDetail,
            comments: postDetail.comments.filter(comment => comment._id !== payload.commentId)
          }
        }
      }

      const newPosts = [...state.posts.map(post => ({ ...post }))];

      newPosts && newPosts.length && newPosts.forEach(post => {
        if (payload.postId === post._id) {
          post.comments = post.comments.filter(comment => comment._id !== payload.commentId);
        }
      });

      return {
        ...state,
        posts: newPosts,
        post: postDetail,
        loading: false
      };
    // --------------- New Posts Feature -----------------
    case GET_POST_DRAFTS:
      return {
        ...state,
        fetchingDrafts: true
      }
    case GET_POST_DRAFTS_SUCCESS:
      return {
        ...state,
        fetchingDrafts: false,
        drafts: payload.drafts
      }
    case GET_POST_DRAFTS_FAILED:
      return {
        ...state,
        fetchingDrafts: false
      }
    case CREATE_POST:
      return {
        ...state,
        savingDraft: true
      }
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        drafts: [...state.drafts, { ...payload.draft }],
        savingDraft: false
      }
    case CREATE_POST_FAILED:
      return {
        ...state,
        savingDraft: false
      }
    case SAVE_DRAFT:
      return {
        ...state,
        savingDraft: true
      }
    case SAVE_DRAFT_SUCCESS:
      const updatedDrafts = [...state.drafts.map(draft => {
        if (payload.draft._id === draft._id) {
          return {
            ...payload.draft
          }
        } else {
          return {
            ...draft
          }
        }
      })];

      return {
        ...state,
        drafts: updatedDrafts,
        savingDraft: false
      }
    case SAVE_DRAFT_FAILED:
      return {
        ...state,
        savingDraft: false
      }
    case PUBLISH_DRAFT:
      return {
        ...state,
        loading: true
      }
    case PUBLISH_DRAFT_SUCCESS:
      return {
        ...state,
        drafts: [...state.drafts.filter(draft => draft._id !== payload)],
        loading: false
      }
    case PUBLISH_DRAFT_FAILED:
      return {
        ...state,
        loading: false
      }
    case DELETE_POST:
      return {
        ...state,
        deletingDraft: true
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        drafts: [...state.drafts.filter(draft => draft._id !== payload)],
        deletingDraft: false
      };
    case DELETE_POST_FAILED:
      return {
        ...state,
        deletingDraft: false
      };
    case GET_COMMENTS:
      return {
        ...state
      }
    case GET_COMMENTS_SUCCESS:
      postDetail = { ...state.post } || null;

      if (postDetail && postDetail._id === payload.postId) postDetail = { ...postDetail, comments: [...payload.comments] }

      return {
        ...state,
        post: postDetail
      }
    case GET_COMMENTS_FAILED:
      return {
        ...state
      }
    default:
      return state;
  }
}
